import React, { createRef, useState, useEffect, useCallback } from 'react';
import MedicalsDocumentsTable from './MedicalsDocumentsTable';
import MedicalsDiagnosesTable from './MedicalsDiagnosesTable';
import MedicalsMRITable from './MedicalsMRITable';
import DeleteMedical from './DeleteMedical';
import { isCompositeDemand } from '../DemandComponents/helpers';
import TemplateUpdateAlert from '../DemandComponents/TemplateUpdateAlert';
import { shouldDisplayAlert } from '../DemandComponents/TemplateUpdateAlert';
import BulkDeleteMedical from './BulkDeleteMedical';
import CsvExportIconButton from './CsvExportIconButton';
import { Transition } from '../Transition';
import ResizableWrapWithPdfViewer from '../CommonComponents/ResizableWrapWithPdfViewer';
import AddMedicalTreatment from './AddMedicalTreatment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  AlertTitle,
  Autocomplete,
  TextField,
  Button,
  Box,
  InputAdornment,
  Tooltip,
  Stack,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Badge,
} from '@mui/material';
import { AddCircleOutline, EditOutlined, DeleteOutline, WarningAmberOutlined, AutoAwesome } from '@mui/icons-material';
import {
  getCurrentDate,
  formatDecimal,
  formatCurrency,
  formatDate,
  userHasPermission,
  convertUTCDateToLocalDate,
  cleanNumber,
  convertUTCDateToLocalDateForEdit,
  DATE_FILTER_OPERATORS,
} from '../common';
import { currencyComparator } from '../comparators';
import { oneDay } from '../common-data';
import { getMedicalTreatmentAlerts, getMedicalsIcdCodesAlerts } from './medicals';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiRef,
  getGridDateOperators,
} from '@mui/x-data-grid-pro';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectionModel,
  setCurrentDeleteMedical,
  setTreatmentProviderChartLegend,
} from '../redux/slices/medicalsTreatmentsSlice';
import {
  setMedicalsScrollFrameRef,
  setDeleteMedicalOpen,
  setBulkDeleteOpen,
  setBulkEditOpen,
  setMedicalsTableIndex,
  setPdfPanelOpen,
} from '../redux/slices/demandDomSlice';
import { setAggregatedIcdCodeData } from '../redux/slices/icdCodesSlice';
import { fetchDocumentData } from '../redux/thunks/documentData';
import { postMedicalsData } from '../redux/thunks/medicalsData';
import { fetchDocumentInsightsMriData } from '../redux/thunks/documentInsightsData';
import { setAddICDCode, setEditICDCode, setEditMRIFinding } from '../redux/slices/globalDialogueSlice';
import Title from '../CommonComponents/Title';
import moment from 'moment';
import TableNoRowsOverlay from './TableNoRowsOverlay';
import { aggregateIcdCodes } from './pageInsights';
import { deepPurple } from '@mui/material/colors';

const slots = {
  noRowsOverlay: TableNoRowsOverlay,
};

const slotProps = {
  noRowsOverlay: {
    text: 'No medical treatments',
  },
};

const badgeStyleProps = { '.MuiBadge-badge': { right: '6px' } };
const tabStyleProps = { minHeight: '48px', pr: 2.5, pl: 2.5 };

const MedicalsTreatments = ({ userData, user, viewType, pin = '', documentId, documentDataProp, carrierMedicals }) => {
  const dispatch = useDispatch();

  const { medicalsLoading } = useSelector((state) => state.Medicals);
  const authenticatedMeds = useSelector((state) => state.Medicals.medicals);
  const medicals = carrierMedicals || authenticatedMeds;

  const { selectionModel, treatmentProviderChartLegend, treatmentProviderChartDateRange, currentDeleteMedical } =
    useSelector((state) => state.MedicalsTreatments);

  const {
    deleteMedicalOpen,
    medicalsScrollFrameRef,
    bulkEditOpen,
    bulkDeleteOpen,
    medicalsTableIndex,
    pdfPanelOpen,
    pdfInstance,
    documentFiles,
  } = useSelector((state) => state.DemandDom);

  const { documentDataLoading, documentRegnerationLoading, documentFilesLoading } = useSelector(
    (state) => state.Document
  );
  const documentDataState = useSelector((state) => state.Document);
  const { documentMetaData, documentS3Url } = documentDataState;

  const documentDataOnState = documentDataState.documentData;
  const documentData = documentDataProp || documentDataOnState;

  const { customerData } = useSelector((state) => state.Customer);
  const { displayMriFindings } = customerData;

  const { mriInsightsBody } = useSelector((state) => state.DocumentInsights);

  const { templateBody, templateBodyForPinnedVersion } = useSelector((state) => state.Template);

  const { mriSelectedRows } = useSelector((state) => state.MriFindings);

  const { aggregatedIcdCodeData, selectedRowsAndIcdCodeIds } = useSelector((state) => state.IcdCodes);

  const isComposite = isCompositeDemand(documentMetaData?.documentType);
  const [anchorEl, setAnchorEl] = useState();
  const [addMedicalOpen, setAddMedicalOpen] = useState(false);
  const [confirmationWindowOpen, setconfirmationWindowOpen] = useState(false);
  const [bulkEditData, setBulkEditData] = useState({});
  const [bulkEditOldData, setBulkEditOldData] = useState({});
  const [addMedicalMenuView, setAddMedicalMenuView] = useState(false);
  const [confirmationWindowMenuView, setConfirmationWindowMenuView] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentBoundingBoxes, setCurrentBoundingBoxes] = useState([]);
  const [currentPageTrigger, setCurrentPageTrigger] = useState(0);
  const [gridHeight, setGridHeight] = useState('75vh');
  const [documentDataFetched, setDocumentDataFetched] = useState(false);
  const [awaitingNavigation, setAwaitingNavigation] = useState(false);
  const MULTIPLE_VALUES = '[Multiple values]';
  const scrollFrameRef = createRef();
  const hasDocumentData = documentData && Object.keys(documentData).length > 0;
  const canComposeDemand = userHasPermission('DemandCompose', userData);
  const isDocumentsTableToggledOn = medicalsTableIndex === 0;
  const isMedicalsTableToggledOn = medicalsTableIndex === 1;
  const isDiagnosesTableToggledOn = medicalsTableIndex === 2;
  const isMRITableToggledOn = medicalsTableIndex === 3;

  useEffect(() => {
    if (!documentDataFetched && !hasDocumentData) {
      dispatch(fetchDocumentData({ documentId, user }));
      setDocumentDataFetched(true);
    }
  }, [documentDataFetched, hasDocumentData]);

  useEffect(() => {
    if (documentFiles.length > 0) {
      dispatch(setAggregatedIcdCodeData(aggregateIcdCodes(documentFiles)));
    }
  }, [documentFiles]);

  useEffect(() => {
    !medicalsScrollFrameRef && dispatch(setMedicalsScrollFrameRef(scrollFrameRef.current));
  }, [scrollFrameRef.current]);

  useEffect(() => {
    dispatch(setMedicalsTableIndex(0));
    dispatch(fetchDocumentInsightsMriData({ documentId, user }));
  }, [documentId, user]);

  useEffect(() => {
    const uniqueTreatmentNamesArray = (medicals?.medicalTreatments || [])
      .map((row) => row.treatmentFacility.toUpperCase())
      .filter((value, index, self) => self.indexOf(value) === index);
    const newLegend = uniqueTreatmentNamesArray.reduce((acc, value) => {
      acc[value] = true;
      return acc;
    }, {});
    dispatch(setTreatmentProviderChartLegend(newLegend));
  }, [medicals]);

  useEffect(() => {
    if (awaitingNavigation && pdfPanelOpen && pdfInstance) {
      setAwaitingNavigation(false);
      setTimeout(() => {
        setCurrentPageTrigger(currentPageTrigger + 1);
      }, 3000);
    }
  }, [pdfPanelOpen, pdfInstance]);

  const hasMedicalTreatments = medicals?.medicalTreatments?.length || 0 > 0;
  const medicalTreatmentAlerts = getMedicalTreatmentAlerts(
    documentData,
    viewType,
    hasMedicalTreatments,
    false,
    medicals
  );

  const hasIcdCodesFlaggedForReview = Object.values(aggregatedIcdCodeData).some(({ needsReview }) => needsReview);
  const icdCodesAlerts = getMedicalsIcdCodesAlerts(hasIcdCodesFlaggedForReview);

  const processRowUpdate = (newRow, originalRow) => {
    if (newRow.billedAmount) {
      newRow.billedAmount = cleanNumber(newRow.billedAmount);
    }
    const updatedRow = { ...newRow };
    if (JSON.stringify(newRow) !== JSON.stringify(originalRow)) {
      const updatedMedicals = {
        ...medicals,
        medicalTreatments: medicals.medicalTreatments.map((row) =>
          row?.medicalTreatmentId === newRow?.medicalTreatmentId ? updatedRow : row
        ),
      };
      dispatch(
        postMedicalsData({
          documentId: documentData.documentId,
          medicals: updatedMedicals,
          user,
        })
      ).catch((err) => console.log(err));
    }
    return updatedRow;
  };

  const handleAddMedicalsClick = () => {
    setAddMedicalOpen(true);
    addMedicalMenuView && setAddMedicalMenuView(false);
  };

  const handleAddMedicalsClose = () => setAddMedicalOpen(false);

  const handleBulkEditClick = () => {
    setBulkEditData({});
    setBulkEditOldData({});
    dispatch(setBulkEditOpen(true));
  };

  const handleConfirmationWindowClick = () => {
    setconfirmationWindowOpen(true);
    confirmationWindowMenuView && handleMenuClose();
  };

  const handleConfirmationWindowClose = () => setconfirmationWindowOpen(false);

  const getBulkEditFormData = (id) => bulkEditData[id];

  const handleDeleteMedicalClick = (e, row) => {
    dispatch(setCurrentDeleteMedical(row));
    dispatch(setDeleteMedicalOpen(true));
  };

  const handleBulkEditFormElementChange = (event, id, newValue = null) => {
    const elementId = id || event.target.id;
    newValue = newValue || event.target.value;
    if (bulkEditOldData[elementId] !== newValue) {
      bulkEditData[elementId] = newValue;
      setBulkEditData({ ...bulkEditData });
    }
  };

  const handleBulkEditFormElementReset = (event, id) => {
    const elementId = id || event.target.id;
    delete bulkEditData[elementId];
    setBulkEditData({ ...bulkEditData });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSaveConfirmationWindowClick = () => {
    const newMedicalTreatments = [...medicals.medicalTreatments].map((row) => {
      if (selectionModel.includes(row?.medicalTreatmentId)) {
        return {
          ...row,
          ...bulkEditData,
          ...(bulkEditData?.billedAmount && { billedAmount: cleanNumber(bulkEditData.billedAmount) }),
        };
      }
      return row;
    });

    const uniqueTreatmentNamesArray = newMedicalTreatments
      .map((row) => row.treatmentFacility.toUpperCase())
      .filter((value, index, self) => self.indexOf(value) === index);

    const newLegend = uniqueTreatmentNamesArray.reduce((acc, value) => {
      acc[value] = true;
      return acc;
    }, {});

    dispatch(setTreatmentProviderChartLegend(newLegend));

    const updatedMedicals = {
      ...medicals,
      medicalTreatments: newMedicalTreatments,
    };
    dispatch(
      postMedicalsData({
        documentId: documentData.documentId,
        medicals: updatedMedicals,
        user,
      })
    );
    setconfirmationWindowOpen(false);
    dispatch(setBulkEditOpen(false));
  };

  const handleSaveBulkDeleteClick = () => {
    const newMedicalsData = {
      ...medicals,
      medicalTreatments: medicals.medicalTreatments.filter(
        (item) => !selectionModel.includes(item?.medicalTreatmentId)
      ),
    };

    dispatch(
      postMedicalsData({
        documentId: documentData.documentId,
        medicals: newMedicalsData,
        user,
      })
    );

    dispatch(setBulkDeleteOpen(false));
  };

  const handleSelectionModelChange = (newSelection) => dispatch(setSelectionModel(newSelection));

  const deleteMedical = () => {
    const newMedicalsData = {
      ...medicals,
      medicalTreatments: medicals.medicalTreatments.filter(
        (item) => item?.medicalTreatmentId !== currentDeleteMedical?.medicalTreatmentId
      ),
    };

    dispatch(
      postMedicalsData({
        documentId: documentData.documentId,
        medicals: newMedicalsData,
        user,
      })
    );

    dispatch(setDeleteMedicalOpen(false));
  };

  const handleViewPageInDocument = (pageNumber, boundingBoxes) => {
    setCurrentPage(parseInt(pageNumber) - 1);
    setCurrentBoundingBoxes(boundingBoxes);
    if (!pdfPanelOpen) {
      setAwaitingNavigation(true);
      dispatch(setPdfPanelOpen(true));
    } else {
      setCurrentPageTrigger((currentPageTrigger) => currentPageTrigger + 1);
    }
  };

  const filterByLegendAndDateRange = (treatments) => {
    const filteredTreatmentsByLegend = treatments.filter(
      (treatment) => treatmentProviderChartLegend[treatment.treatmentFacility.toUpperCase()]
    );
    const parsedFilteredTreatmentsByLegend = filteredTreatmentsByLegend.map((treatment) => {
      return {
        ...treatment,
        billedAmount: treatment.billedAmount ? formatDecimal(treatment.billedAmount) : '',
      };
    });

    if (treatmentProviderChartDateRange.start && treatmentProviderChartDateRange.end) {
      return filteredTreatmentsByLegend.filter((treatment) => {
        const treatmentDateAtMidnight = new Date(treatment.treatmentDate).setHours(0, 0, 0, 0);
        const startDateAtMidnight = new Date(treatmentProviderChartDateRange.start).setHours(0, 0, 0, 0);
        const endDateAtMidnight = new Date(treatmentProviderChartDateRange.end).setHours(0, 0, 0, 0);
        return (
          treatmentDateAtMidnight + oneDay >= startDateAtMidnight &&
          treatmentDateAtMidnight + oneDay <= endDateAtMidnight
        );
      });
    }
    return parsedFilteredTreatmentsByLegend;
  };

  const apiRef = useGridApiRef();

  const filterDateOperators = getGridDateOperators().filter((operator) =>
    DATE_FILTER_OPERATORS.includes(operator.value)
  );

  function transformDateToZeroHour(input) {
    const date = new Date(input);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  const customFilterDateOperators = filterDateOperators.map((operator) => ({
    ...operator,
    getApplyFilterFn: (filterItem) => {
      return (params) => {
        const transformedFilterValue = transformDateToZeroHour(filterItem.value);
        const transformedParamValue = transformDateToZeroHour(params.value);

        if (isNaN(transformedFilterValue.getTime())) {
          return true;
        }

        switch (operator.value) {
          case 'is':
            return transformedParamValue.getTime() === transformedFilterValue.getTime();
          case 'after':
            return transformedParamValue.getTime() > transformedFilterValue.getTime();
          case 'onOrAfter':
            return transformedParamValue.getTime() >= transformedFilterValue.getTime();
          case 'before':
            return transformedParamValue.getTime() < transformedFilterValue.getTime();
          case 'onOrBefore':
            return transformedParamValue.getTime() <= transformedFilterValue.getTime();
          default:
            // Continue for other cases or fallback
            return true;
        }
      };
    },
  }));

  const columns = [
    {
      field: 'treatmentFacility',
      headerName: 'Facility',
      width: viewType !== 'carrier' ? 200 : 275,
      headerAlign: 'left',
      valueGetter: (params) => params.row.treatmentFacility.toUpperCase(),
      editable: userHasPermission('MedicalsEdit', userData),
      pinnable: false,
      renderCell: (params) =>
        params.row.treatmentFacility && (
          <Box>
            {viewType === 'carrier' ? (
              params.row.treatmentFacility.toUpperCase()
            ) : (
              <Stack direction="row" spacing={1} alignItems={'center'}>
                {params.row.treatmentDate < documentData.claimInfo.dateOfLoss && (
                  <Tooltip
                    title={`Date of service is prior to date of loss: ${formatDate(new Date(documentData.claimInfo.dateOfLoss))}`}
                    arrow
                    placement="top-end"
                  >
                    <WarningAmberOutlined color="warning" />
                  </Tooltip>
                )}
                <Typography
                  variant="body2"
                  color="secondary"
                  onClick={(e) => {
                    handleViewPageInDocument(params.row.generatedPageNumber, [params.row.boundingBox]);
                  }}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  {params.row.treatmentFacility.toUpperCase()}
                </Typography>
              </Stack>
            )}
          </Box>
        ),
    },
    {
      field: 'treatmentType',
      headerName: 'Type',
      width: 275,
      headerAlign: 'left',
      valueGetter: (params) => params.row.treatmentType,
      editable: userHasPermission('MedicalsEdit', userData),
      pinnable: false,
    },
    {
      field: 'treatmentDate',
      headerName: 'Date of service',
      type: 'date',
      minWidth: 160,
      headerAlign: 'right',
      align: 'right',
      filterOperators: customFilterDateOperators,
      valueGetter: (params) => (params.row.treatmentDate ? new Date(params.row.treatmentDate) : 0),
      renderCell: (params) => (
        <div className="MuiDataGrid-cellContent">{formatDate(new Date(params.row.treatmentDate))}</div>
      ),
      editable: userHasPermission('MedicalsEdit', userData),
      renderEditCell: (params) => (
        <TextField
          type="date"
          id="date_of_service"
          inputProps={{ max: getCurrentDate() }}
          defaultValue={moment(formatDate(new Date(params.row.treatmentDate))).format('YYYY-MM-DD')}
          fullWidth
          onChange={(event) => {
            params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value }, event);
          }}
        />
      ),
      pinnable: false,
    },
    {
      field: 'billedAmount',
      headerName: 'Charged amount',
      width: 170,
      type: 'number',
      headerAlign: 'right',
      align: 'right',
      sortComparator: currencyComparator,
      valueGetter: (params) => params.row.billedAmount,
      renderCell: (params) => (params.row.billedAmount ? formatCurrency(params.row.billedAmount) : ''),
      editable: userHasPermission('MedicalsEdit', userData),
      pinnable: false,
    },
    {
      field: 'treatments',
      headerName: 'Treatments',
      width: 120,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (params) => params.row.treatments,
      editable: userHasPermission('MedicalsEdit', userData),
      pinnable: false,
    },
    {
      field: 'generatedPageNumber',
      headerName: 'Page',
      width: 110,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.generatedPageNumber,
      editable: false,
      pinnable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 50,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) =>
        userHasPermission('MedicalsEdit', userData) && viewType === 'precedent' ? (
          <IconButton
            onClick={(e) => {
              handleDeleteMedicalClick(e, params.row);
            }}
          >
            <DeleteOutline />
          </IconButton>
        ) : (
          <></>
        ),
      editable: false,
      sortable: false,
      resizable: false,
      pinnable: false,
    },
  ];

  const filteredTreatments = filterByLegendAndDateRange(medicals?.medicalTreatments || []);
  const isSaveDisabled = selectionModel.length === 0 || Object.keys(bulkEditData).length === 0;
  const userCanEdit = userHasPermission('MedicalsEdit', userData);
  const userCanPreviewDemand = userHasPermission('DemandPreview', userData);

  const handleTabChange = (e, newValue) => {
    // Use newValue instead of e.target.tabIndex because clicking the tab icon gives the wrong tabIndex.
    dispatch(setMedicalsTableIndex(newValue));
  };

  function a11yProps(index) {
    return {
      id: `medical-tab-${index}`,
      'aria-controls': `medical-tabpanel-${index}`,
    };
  }

  const getRowClassName = useCallback(
    (params) => {
      const evenOrOddClassName = params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd';
      if (params.row.treatmentDate < documentData.claimInfo.dateOfLoss) {
        return `${evenOrOddClassName} grid-row-warning`;
      }
      return evenOrOddClassName;
    },
    [documentData?.claimInfo?.dateOfLoss]
  );

  const handleBulkEditIcdCodeClick = () => {
    dispatch(
      setEditICDCode({
        open: true,
        data: { documentId, user },
      })
    );
  };

  const handleBulkEditMriClick = () => {
    dispatch(
      setEditMRIFinding({
        open: true,
        data: { mriSelectedRows, documentId, user },
      })
    );
  };

  const shouldShowTemplateUpdateAlert = shouldDisplayAlert(templateBody, templateBodyForPinnedVersion, documentMetaData);

  return (
    <>
      <div style={{ width: '100%', display: 'flex', marginBottom: '24px' }} ref={scrollFrameRef}>
        <ResizableWrapWithPdfViewer
          documentS3Url={documentS3Url}
          currentPage={currentPage}
          currentBoundingBoxes={currentBoundingBoxes}
          currentPageTrigger={currentPageTrigger}
          pdfHeight={gridHeight}
          user={user}
          documentId={documentId}
        >

          <Box sx={{ mr: documentS3Url ? 1 : 0 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1, height: 40 }}>
              {(!isComposite) && <Title>Medical treatments</Title>}

              {userCanPreviewDemand && isComposite && (
                <Tabs value={medicalsTableIndex} onChange={handleTabChange}>
                  <Tab
                    label="Documents"
                    tabIndex={0}
                    {...a11yProps(0)}
                    icon={shouldShowTemplateUpdateAlert && <Badge badgeContent='1' color="error" sx={badgeStyleProps} />}
                    iconPosition='start'
                    sx={tabStyleProps}
                  />

                  <Tab
                    label="Medical treatments"
                    icon={
                      medicalTreatmentAlerts.length > 0 ? (
                        <Badge badgeContent={medicalTreatmentAlerts.length} color="error" sx={badgeStyleProps} />
                      ) : (
                        ''
                      )
                    }
                    iconPosition="start"
                    tabIndex={1}
                    {...a11yProps(1)}
                    sx={tabStyleProps}
                  />
                  <Tab
                    label="Diagnoses"
                    icon={
                      icdCodesAlerts.length > 0 ? (
                        <Badge badgeContent={icdCodesAlerts.length} color="error" sx={badgeStyleProps} />
                      ) : (
                        ''
                      )
                    }
                    iconPosition="start"
                    tabIndex={2}
                    {...a11yProps(2)}
                    sx={tabStyleProps}
                  />
                  {displayMriFindings && (
                    <Tab
                      label="MRI Findings"
                      tabIndex={3}
                      {...a11yProps(3)}
                      icon={<AutoAwesome fontSize="small" color="primary" sx={{ color: deepPurple[900] }} />}
                      iconPosition="start"
                      sx={tabStyleProps}
                    />
                  )}
                </Tabs>
              )}

              <Stack direction="row">
                <>
                  {userCanEdit && !addMedicalMenuView && (isMedicalsTableToggledOn || !isComposite) && (
                    <>
                      <Tooltip title="Add medical treatment" placement="top" arrow>
                        <IconButton onClick={handleAddMedicalsClick}>
                          <AddCircleOutline />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        title={
                          selectionModel.length === 0 ? 'Select a treatment to enable' : 'Edit selected treatment(s)'
                        }
                        arrow
                      >
                        <span>
                          <IconButton disabled={selectionModel.length === 0} onClick={handleBulkEditClick}>
                            <EditOutlined />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        title={
                          selectionModel.length === 0 ? 'Select a treatment to enable' : 'Delete selected treatment(s)'
                        }
                        arrow
                      >
                        <span>
                          <IconButton
                            disabled={selectionModel.length === 0}
                            onClick={() => dispatch(setBulkDeleteOpen(true))}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  )}
                  {(isMedicalsTableToggledOn || !canComposeDemand || !isComposite) && (
                    <CsvExportIconButton
                      filteredTreatments={filteredTreatments}
                      user={user}
                      documentData={documentData}
                      apiRef={apiRef}
                      pin={pin}
                      columns={columns}
                    />
                  )}
                  {isDiagnosesTableToggledOn && canComposeDemand && (
                    <>
                      <Tooltip title="Add ICD code" placement="top" arrow>
                        <IconButton onClick={() => dispatch(setAddICDCode({ open: true, data: { documentId, user } }))}>
                          <AddCircleOutline />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        title={
                          Object.keys(selectedRowsAndIcdCodeIds).length === 1
                            ? 'Edit selected row'
                            : 'Edit selected rows'
                        }
                        arrow
                      >
                        <span>
                          <IconButton
                            onClick={handleBulkEditIcdCodeClick}
                            disabled={Object.keys(selectedRowsAndIcdCodeIds).length === 0}
                          >
                            <EditOutlined />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  )}
                  {isMRITableToggledOn && canComposeDemand && (
                    <Tooltip
                      placement="top"
                      title={mriSelectedRows.length === 1 ? 'Edit selected row' : 'Edit selected rows'}
                      arrow
                    >
                      <span>
                        <IconButton
                          onClick={handleBulkEditMriClick}
                          size="small"
                          disabled={mriSelectedRows.length === 0}
                        >
                          <EditOutlined />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </>
              </Stack>
            </Stack>
            {/* This box acts as the parent container for the following DataGrid tables, providing the height */}
            <Box height={gridHeight} display="flex" flexDirection="column">

              {isDocumentsTableToggledOn && shouldShowTemplateUpdateAlert && (
                <Box
                  sx={{
                    mb: 1
                  }}
                >
                  <TemplateUpdateAlert user={user} templateBody={templateBody}
                    pinnedTemplateBody={templateBodyForPinnedVersion} documentMetaData={documentMetaData} viewType={viewType} />
                </Box>
              )}

              {isDocumentsTableToggledOn && isComposite && userCanPreviewDemand && (
                <MedicalsDocumentsTable
                  documentData={documentData}
                  user={user}
                  viewType={viewType}
                  setCurrentPage={setCurrentPage}
                  setCurrentPageTrigger={setCurrentPageTrigger}
                  setCurrentBoundingBoxes={setCurrentBoundingBoxes}
                  currentPage={currentPage}
                  currentPageTrigger={currentPageTrigger}
                  currentBoundingBoxes={currentBoundingBoxes}
                  documentRegnerationLoading={documentRegnerationLoading}
                  documentDataLoading={documentDataLoading}
                />
              )}

              {(isMedicalsTableToggledOn || !isComposite) && (
                <>
                  {medicalTreatmentAlerts.map((alert, index) => {
                    return (
                      <Alert key={index} severity={alert.alertSeverity} sx={{ mb: 1 }}>
                        {alert.alertMessage}
                      </Alert>
                    );
                  })}

                  {/* These two boxes are a bit of a hack to force the browser to calculate the DataGrid's dimensions, allowing it to be height responsive. */}
                  <Box sx={{ flex: 1, position: 'relative' }}>
                    <Box sx={{ position: 'absolute', inset: 0 }}>
                      <DataGridPro
                        loading={documentDataLoading || documentRegnerationLoading || medicalsLoading}
                        autoHeight={false}
                        getRowId={(row) => row?.medicalTreatmentId}
                        columns={columns}
                        rows={filteredTreatments}
                        columnVisibilityModel={{
                          actions: viewType === 'carrier' || viewType === 'lawfirm' ? false : true,
                        }}
                        onColumnWidthChange={() => apiRef.current.resetRowHeights()}
                        unstable_cellSelectionModel={{ 1: { gotopage: false } }}
                        selectionModel={selectionModel}
                        onCellEditStop={(event) => (event.defaultMuiPrevented = false)}
                        rowSelectionModel={selectionModel}
                        onRowSelectionModelChange={handleSelectionModelChange}
                        checkboxSelection={userHasPermission('MedicalsEdit', userData) && viewType !== 'lawfirm'}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: 'treatmentDate', sort: 'asc' }],
                          },
                          pagination: {
                            paginationModel: { pageSize: 100, page: 0 },
                          },
                          pinnedColumns: {
                            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'treatmentFacility', 'gotopage'],
                          },
                        }}
                        editMode={viewType === 'precedent' && 'cell'}
                        apiRef={apiRef}
                        processRowUpdate={(updatedRow, originalRow) => processRowUpdate(updatedRow, originalRow)}
                        disableRowSelectionOnClick={true}
                        onProcessRowUpdateError={(error) => alert(error)}
                        disableColumnSelector={true}
                        pageSizeOptions={[25, 50, 100]}
                        getRowHeight={() => 'auto'}
                        getRowClassName={getRowClassName}
                        slots={slots}
                        slotProps={slotProps}
                      />
                    </Box>
                  </Box>
                </>
              )}

              {isDiagnosesTableToggledOn && (
                <MedicalsDiagnosesTable
                  documentFiles={documentFiles}
                  documentId={documentId}
                  handleViewPageInDocument={handleViewPageInDocument}
                  user={user}
                  documentFilesLoading={documentFilesLoading}
                  aggregatedIcdCodeData={aggregatedIcdCodeData}
                  icdCodesAlerts={icdCodesAlerts}
                  userData={userData}
                />
              )}

              {isMRITableToggledOn && (
                <MedicalsMRITable
                  documentFiles={documentFiles}
                  mriInsightsBody={mriInsightsBody}
                  documentId={documentId}
                  handleViewPageInDocument={handleViewPageInDocument}
                  user={user}
                  documentFilesLoading={documentFilesLoading}
                  userData={userData}
                />
              )}
            </Box>
          </Box>
        </ResizableWrapWithPdfViewer>
      </div>

      {deleteMedicalOpen && <DeleteMedical deleteMedical={deleteMedical} />}

      <Dialog
        open={bulkEditOpen}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle>Bulk edit medical treatments</DialogTitle>

        <DialogContent>
          <Alert severity="info" spacing={2} sx={{ mb: 2 }}>
            <AlertTitle>
              {selectionModel.length} medical treatment{selectionModel.length > 1 ? 's' : ''} selected
            </AlertTitle>
            {selectionModel.length > 1
              ? 'Edit a field to apply the value to all selected medical treatments. Leave a field unchanged to maintain its value(s) as is.'
              : 'Edit the fields below to apply the changes to the selected medical treatment.'}
          </Alert>

          {selectionModel.length > 0 ? (
            <Stack spacing={2} sx={{ mt: 1 }}>
              {columns.map((column) => {
                if (column.field !== 'actions' && column.field !== 'gotopage' && bulkEditOpen) {
                  let multipleValues = false;
                  bulkEditOldData[column.field] = undefined;
                  selectionModel.forEach((rowId) => {
                    if (
                      bulkEditOldData[column.field] !== undefined &&
                      bulkEditOldData[column.field] !== apiRef.current.getRow(rowId)[column.field]
                    ) {
                      bulkEditOldData[column.field] = MULTIPLE_VALUES;
                      multipleValues = true;
                    } else {
                      bulkEditOldData[column.field] = apiRef.current.getRow(rowId)[column.field];
                      if (column.type === 'date') {
                        bulkEditOldData[column.field] = convertUTCDateToLocalDate(bulkEditOldData[column.field]);
                      } else if (column.field === 'billedAmount') {
                        bulkEditOldData[column.field] = formatDecimal(bulkEditOldData[column.field]);
                      }
                    }
                  });
                  const value = bulkEditOldData[column.field] ? bulkEditOldData[column.field].toString() : '';

                  let displayValue =
                    getBulkEditFormData(column.field) !== undefined
                      ? getBulkEditFormData(column.field)
                      : multipleValues
                        ? ''
                        : value;

                  if (column.type === 'date') {
                    if (displayValue !== '') {
                      displayValue = convertUTCDateToLocalDateForEdit(displayValue);
                    }
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }} key={column.field}>
                        <TextField
                          id={column.field}
                          label={column.headerName}
                          inputProps={{ max: getCurrentDate() }}
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={displayValue}
                          onChange={(e) => {
                            handleBulkEditFormElementChange(e, column.field);
                          }}
                          InputProps={{
                            endAdornment:
                              getBulkEditFormData(column.field) !== undefined ? (
                                <InputAdornment position="end">
                                  <Button
                                    color="error"
                                    onClick={(e) => {
                                      handleBulkEditFormElementReset(e, column.field, value);
                                    }}
                                    sx={{ mr: 3 }}
                                  >
                                    Reset
                                  </Button>
                                </InputAdornment>
                              ) : null,
                          }}
                          helperText={
                            multipleValues ? 'Multiple values' : `Current value: ${formatDate(new Date(value))}`
                          }
                        ></TextField>
                      </div>
                    );
                  } else if (column.field === 'billedAmount') {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }} key={column.field}>
                        <TextField
                          id={column.field}
                          label={column.headerName}
                          fullWidth
                          value={formatDecimal(displayValue)}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment:
                              getBulkEditFormData(column.field) !== undefined ? (
                                <InputAdornment position="end">
                                  <Button
                                    color="error"
                                    onClick={(e) => {
                                      handleBulkEditFormElementReset(e, column.field, value);
                                    }}
                                    sx={{ mr: 3 }}
                                  >
                                    Reset
                                  </Button>
                                </InputAdornment>
                              ) : null,
                          }}
                          onChange={(e) => {
                            let newValue = formatDecimal(e.target.value);
                            handleBulkEditFormElementChange(e, column.field, newValue);
                          }}
                          helperText={multipleValues ? 'Multiple values' : `Current value: ${formatCurrency(value)}`}
                        ></TextField>
                      </div>
                    );
                  } else if (column.field !== 'generatedPageNumber') {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }} key={column.field}>
                        <Autocomplete
                          options={Array.from(
                            new Set(
                              medicals.medicalTreatments
                                .map((row) => row[column.field])
                                .filter((value) => value != null && value !== '')
                            )
                          )}
                          freeSolo
                          value={displayValue}
                          fullWidth
                          multiline
                          getOptionLabel={(option) =>
                            typeof option === 'string' || option instanceof String ? option : ''
                          }
                          onChange={(e, newValue) => handleBulkEditFormElementChange(e, column.field, newValue)}
                          onInputChange={(e, newValue) => handleBulkEditFormElementChange(e, column.field, newValue)}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <TextField
                                {...params}
                                label={column.headerName}
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment:
                                    getBulkEditFormData(column.field) !== undefined ? (
                                      <InputAdornment position="end">
                                        <Button
                                          color="error"
                                          onClick={(e) => {
                                            handleBulkEditFormElementReset(e, column.field, value);
                                          }}
                                          sx={{}}
                                        >
                                          Reset
                                        </Button>
                                      </InputAdornment>
                                    ) : null,
                                }}
                                helperText={multipleValues ? 'Multiple values' : `Current value: ${value}`}
                              />
                            </div>
                          )}
                        />
                      </div>
                    );
                  }
                }
              })}
            </Stack>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button color="error" onClick={() => dispatch(setBulkEditOpen(false))}>
            Discard changes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={isSaveDisabled}
            onClick={(e) => {
              handleConfirmationWindowClick();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {bulkDeleteOpen && <BulkDeleteMedical handleSaveBulkDeleteClick={handleSaveBulkDeleteClick} />}

      <Dialog
        open={confirmationWindowOpen}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle>Confirm bulk edit</DialogTitle>

        <DialogContent>
          <Alert severity={'info'} spacing={2} sx={{ mb: 2 }}>
            You are about to edit the following fields across {selectionModel.length} medical treatment
            {selectionModel.length > 1 ? 's' : ''}:
          </Alert>
          <TableContainer
            sx={{
              borderRadius: 1,
              borderLeft: 1,
              borderRight: 1,
              borderTop: 1,
              borderColor: 'divider',
            }}
          >
            <Table
              size="small"
              sx={{
                '&:last-child': {
                  borderBottom: 0,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Field</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Old value</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>New value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(bulkEditData).map((key) => (
                  <TableRow key={key}>
                    <TableCell>{columns.find((column) => column.field === key).headerName}</TableCell>
                    <TableCell>{bulkEditOldData[key]}</TableCell>
                    <TableCell>{bulkEditData[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              handleConfirmationWindowClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => {
              handleSaveConfirmationWindowClick();
            }}
          >
            Apply changes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addMedicalOpen}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle>Add Medical Treatment</DialogTitle>
        <DialogContent>
          <AddMedicalTreatment
            handleAddMedicalsClose={handleAddMedicalsClose}
            setAddMedicalOpen={setAddMedicalOpen}
            documentData={documentData}
            medicals={medicals}
            user={user}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MedicalsTreatments;
