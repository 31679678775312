import React, { useEffect, useRef } from 'react';
import { pushDataToS3, getUploadUrl, handleUpdateFilePdf, regenerateDocumentStatusApi, postMedicalsToFile, postPageInsightsToFile, getDocumentFilesApi } from '../api';
import { useDispatch, useSelector } from 'react-redux';
import { setEditOneDocumentFileName, setDocumentEdit3Url, setDocumentFiles, setEditDocumentPdfOpen, setFileDisabled, setFileSaveStatusMap, setEditAllDocumentsOpen } from '../redux/slices/demandDomSlice';
import { setDocumentDataLoading, setDocumentRegnerationLoading } from '../redux/slices/documentSlice';
import { fetchDocumentData } from '../redux/thunks/documentData';
import { fetchMedicalsData } from '../redux/thunks/medicalsData';
import PSPDFKit from 'pspdfkit';

const height = window.innerHeight - 64;
const licenseKey = process.env.REACT_APP_PSPDF_LICENSE_KEY;
const baseUrl = `${window.location.protocol}//${window.location.host}/assets/`

const PdfEditor = ({ documentS3Url, user, documentId, file, setToastMessage, setToastSeverity, setToastOpen, setLocalDocumentFiles, setIsSaving }) => {
    const fileId = file.fileEntityId
    const dispatch = useDispatch();
    const pdfEditorContainerRef = useRef(null);
    const { editOneDocumentFileName, fileSaveStatusMap } = useSelector(state => state.DemandDom);

    const handleMedicalsUpdate = async (pageNumbersMappedToCorrectIndices, instance) => {
        dispatch(setDocumentRegnerationLoading(documentId));
        dispatch(setDocumentDataLoading(documentId));
        dispatch(setEditAllDocumentsOpen(true));

        const medicalTreatments = file?.fileEntityData?.medicalTreatments || [];
        const pageInsights = file?.fileEntityData?.pageInsights || [];


        const getUploadFieldsAndRegenerate = async () => {
            const uploadFields = await getUploadUrl(editOneDocumentFileName, documentId, user)

            const pdf = await instance.exportPDF()
                .then((buffer) => new Blob([buffer], { type: 'application/pdf' }));

            const success = await pushDataToS3(uploadFields, pdf)

            if (success) {
                await handleUpdateFilePdf(fileId, editOneDocumentFileName, user)
                    .then((response) => {
                        getDocumentFilesApi(documentId, user)
                            .then(response => response.json())
                            .then(data => {
                                dispatch(setDocumentFiles(data));
                                const updatedFile = data.find(file => file.fileEntityId === fileId);
                                setLocalDocumentFiles((prevState) => prevState.map(file => file.fileEntityId === fileId ? updatedFile : file));
                            })
                        checkStatus(documentId);
                    })
                    .catch((error) => {
                        dispatch(setDocumentRegnerationLoading(''));
                        setToastMessage(`Error updating ${editOneDocumentFileName}`);
                        setToastSeverity('error');
                        setToastOpen(true);
                        const fileSaveStatusMapCopy = { ...fileSaveStatusMap };
                        delete fileSaveStatusMapCopy[file.fileEntityId];
                        dispatch(setFileSaveStatusMap(fileSaveStatusMapCopy))
                    })
            }
        }

        const medicalsWithUpdatedPageNumbers = medicalTreatments.map(medical => {
            const currentPageNumber = medical.sourcePageNumber;
            const newPageNumber = pageNumbersMappedToCorrectIndices.indexOf(currentPageNumber) !== - 1 ? pageNumbersMappedToCorrectIndices.indexOf(currentPageNumber) + 1 : - 1;
            return {
                ...medical,
                sourcePageNumber: newPageNumber
            }
        })
            .filter(medical => medical.sourcePageNumber !== -1);

        const pageInsightsWithUpdatedPageNumbers = pageInsights.map(pageInsight => {
            const currentPageNumber = pageInsight.page_number;
            const newPageNumber = pageNumbersMappedToCorrectIndices.indexOf(currentPageNumber) !== - 1 ? pageNumbersMappedToCorrectIndices.indexOf(currentPageNumber) + 1 : - 1;
            return {
                ...pageInsight,
                page_number: newPageNumber
            }
        })
            .filter(pageInsight => pageInsight.page_number !== -1);



        const hasUpdatedMedicals = medicalsWithUpdatedPageNumbers.length > 0;
        const hasUpdatedPageInsights = pageInsightsWithUpdatedPageNumbers.length > 0;

        if (hasUpdatedMedicals) {
            await postMedicalsToFile(fileId, { medicalTreatments: medicalsWithUpdatedPageNumbers }, user)
                .then(async (response) => {
                    if (hasUpdatedPageInsights) {
                        await postPageInsightsToFile(fileId, { pageInsights: pageInsightsWithUpdatedPageNumbers }, user)
                            .then(async (response) => {
                                getUploadFieldsAndRegenerate();
                                return response
                            })
                            .catch(error => error)
                    } else {
                        getUploadFieldsAndRegenerate();
                    }
                    return response
                })
                .catch(error => error)
        } else {
            if (hasUpdatedPageInsights) {
                await postPageInsightsToFile(fileId, { pageInsights: pageInsightsWithUpdatedPageNumbers }, user)
                    .then(async (response) => {
                        getUploadFieldsAndRegenerate();
                        return response
                    })
                    .catch(error => error)
            } else {
                getUploadFieldsAndRegenerate();
                return;
            }
        }
    }

    const checkStatus = async (documentId) => {
        const response = await regenerateDocumentStatusApi(documentId, user);
        const status = response["status"];

        if (status === "Complete") {
            dispatch(fetchMedicalsData({ documentId, user }))
            dispatch(fetchDocumentData({ documentId, user }))
                .then(() => {
                    dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file.fileEntityId]: 'Saved' }));
                    setIsSaving(false);
                    dispatch(setDocumentRegnerationLoading(''));
                    dispatch(setFileDisabled(''))
                    setToastMessage(`Updated ${editOneDocumentFileName}`);
                    setToastSeverity('success');
                    setToastOpen(true);
                })
            return;
        }
        else if (status === "Failed") {
            setToastMessage(`Error updating ${editOneDocumentFileName}`);
            setToastSeverity('error');
            setToastOpen(true);
            dispatch(setDocumentRegnerationLoading(''));
            return;
        }

        setTimeout(() => {
            checkStatus(documentId);
        }, 3000);
    }

    useEffect(() => {
        pdfEditorContainerRef.current && PSPDFKit.unload(pdfEditorContainerRef.current)

        PSPDFKit.load({
            styleSheets: [
                // `${baseUrl}pspdfkit.css`,
                `${baseUrl}customPSPDFstyles.css`
            ],
            licenseKey,
            container: pdfEditorContainerRef.current,
            baseUrl: baseUrl,
            document: documentS3Url,
            initialViewState: new PSPDFKit.ViewState({
                zoom: PSPDFKit.ZoomMode.FIT_WIDTH,
                interactionMode: PSPDFKit.InteractionMode.DOCUMENT_EDITOR
            }),
        })
            .then(instance => {

                const toolbarItemsToRemove = [
                    "sidebar-document-outline", "sidebar-annotations", "sidebar-bookmarks", "annotate", "ink", "highlighter", "text-highlighter", "ink-eraser",
                    "signature", "image", "stamp", "note", "text", "callout", "line", "link", "arrow", "rectangle", "ellipse", "polygon", "cloudy-polygon",
                    "polyline", "document-crop", "multi-annotations-selection", "pan", "print", "sidebar-signatures", "sidebar-layers", "document-editor"
                ];

                const editorItems = ['remove', 'undo', 'redo', 'select-all', 'select-none', 'zoom-out', 'zoom-in', 'rotate-left', 'rotate-right'];
                const footerItems = ['cancel', 'spacer', 'save'];

                instance.setToolbarItems(instance.toolbarItems.filter(item => !toolbarItemsToRemove.includes(item.type)));
                instance.setDocumentEditorToolbarItems(editorItems.map(item => ({ type: item })));
                instance.setDocumentEditorFooterItems(footerItems.map(item => ({ type: item })));

                instance.addEventListener("viewState.change", (viewState, previousState) => {
                    if (previousState.interactionMode?.toLowerCase() === "document_editor") {
                        dispatch(setEditOneDocumentFileName(''))
                        dispatch(setDocumentEdit3Url(''))
                        dispatch(setFileDisabled(''))
                        dispatch(setEditDocumentPdfOpen(false))
                    }
                });

                const applyOperations = (originalArray, operations) => {
                    let originalArrayCopy = [...originalArray];

                    operations.forEach(operation => {
                        if (operation.type === 'movePages') {
                            const pageIndexesToMove = operation.pageIndexes.sort();
                            const pageValuesToMove = pageIndexesToMove.map(pageIndex => originalArrayCopy[pageIndex]);

                            pageIndexesToMove.forEach(page => {
                                originalArrayCopy[page] = undefined;
                            });

                            if (operation?.beforePageIndex !== undefined) {
                                const insertionPoint = Math.max(0, operation.beforePageIndex - 1);
                                originalArrayCopy.splice(insertionPoint, 0, ...pageValuesToMove);
                            } else if (operation?.afterPageIndex !== undefined) {
                                const insertionPoint = Math.min(operation.afterPageIndex + 1, originalArrayCopy.length);
                                originalArrayCopy.splice(insertionPoint, 0, ...pageValuesToMove);
                            }

                            originalArrayCopy = originalArrayCopy.filter(val => val != undefined);
                        } else if (operation.type === 'removePages') {
                            operation.pageIndexes.forEach(pageIndex => {
                                originalArrayCopy[pageIndex] = undefined;
                            });
                            originalArrayCopy = originalArrayCopy.filter(val => val != undefined);
                        }
                    });

                    return originalArrayCopy.map(val => val + 1);
                };

                instance.addEventListener("document.change", async (changes) => {
                    dispatch(setFileDisabled(file.fileEntityId));
                    setIsSaving(true);
                    dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file.fileEntityId]: 'Saving' }));
                    const totalPagesRemoved = changes.filter((change) => change.type === "removePages").map((change) => change.pageIndexes).flat().length;
                    const currentPageCount = instance.totalPageCount;
                    const originalPageCount = currentPageCount + totalPagesRemoved;
                    const originalPageIndexArray = Array.from({ length: originalPageCount }, (_, index) => index);
                    const pageNumbersMappedToCorrectIndices = applyOperations(originalPageIndexArray, changes);

                    handleMedicalsUpdate(pageNumbersMappedToCorrectIndices, instance);


                })
            })
            .catch(error => {
                setToastMessage('Error loading document');
                setToastSeverity('error');
                setToastOpen(true);
            });
    }, [PSPDFKit]);

    return (
        <div ref={pdfEditorContainerRef} style={{ width: "100%", height: '100%', marginTop: '60px' }} />
    );
};

export default PdfEditor;
